.dashboard-timeline-item {
	padding: 6px 16px;
	width: 50%;
}

@media (max-width: 1024px) {
	.dashboard-title {
		text-align: center;
		padding: 0 20px;
		font-size: 30px;
	}

	.dashboard-timeline-item {
		width: 100%;
	}
}

.my-swal {
    z-index: 300000 !important;
}

.sweet-alert {
	z-index: 300000 !important;
}
