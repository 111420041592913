.header {
  padding: 40px;
}

.header h1 {
  font-size: 24px;
  font-weight: 600;
}

.header h2 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 40px;
}
