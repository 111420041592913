.tabContainer {
  padding: 40px 0;
  display: flex;
  gap: 20px;
}

.tab {
  padding: 10px;
  border-radius: 18px;
  cursor: pointer;
}

.selectedTab {
  background-color: #f9fafb;
}
