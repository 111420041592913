.container {
  padding: 30px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 1px solid #eaecf0;
}

.segmentContainer {
  display: flex;
}

.mobileSegmentItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.segmentItem {
  display: flex;
  width: 100%;
}

.segmentLabel {
  font-weight: 500;
  display: flex;
  min-width: 200px;
  width: 20%;
  padding: 10px 0;
}

.segmentColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.segmentContent {
  width: 100%;
}

.pictureUploadContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pictureUpload {
  cursor: pointer;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.mobileSegmentLabel {
  font-weight: 500;
  display: flex;
  min-width: 120px;
  width: 20%;
  padding: 10px 0;
}

.imageContainer {
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  /* overflow: hidden;  */
  /* position: relative;  */
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 3px solid white;
  border-radius: 50%;
}

.imageItem {
  width: 150%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobileImageContainer {
  max-width: 120px;
  max-height: 120px;
  min-width: 120px;
  min-height: 120px;
  /* overflow: hidden;  */
  /* position: relative;  */
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 3px solid white;
  border-radius: 50%;
}

.dropzone {
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 1px solid #eaecf0;
}

.dropzoneContent {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
