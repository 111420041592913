.table-container {
  border-radius: 10px 10px 10px 10px;
  overflow-x: auto;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  -webkit-box-shadow: 0px 0px 27px -3px rgba(234, 236, 240, 1);
  -moz-box-shadow: 0px 0px 27px -3px rgba(234, 236, 240, 1);
  box-shadow: 0px 0px 27px -3px rgba(234, 236, 240, 1);
}

.styled-table th,
.styled-table td {
  padding: 16px 24px;
  text-align: left;
  border: 1px solid #eaecf0;
}

.styled-table th {
  background-color: #eaecf0;
  border: 1px solid #eaecf0;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: space-between;
}

.pagination-button {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  background-color: white;
  border: none;
}

.pagination-items {
  display: flex;
  gap: 20px;
  align-items: center;
}

.tableStatusPending {
  background-color: #eaecf0;
  color: gray;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 500;
  width: fit-content;
  text-transform: capitalize;
}

.header {
  font-weight: 600;
  font-size: 20px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.upgradeButton {
  color: #30077c;
}

.upgradeButton:hover {
  text-decoration: underline;
  cursor: pointer;
}

.nameColumn {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
}

.tableDaysLeft {
  color: #b54708;
  border: 2px solid #b54708;
  padding: 2px 2px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 100px;
}

.tableDaysLeftGreen {
  color: #027a48;
  border: 2px solid #027a48;
  padding: 2px 2px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 100px;
}
